@import '../../utils/_variables';

.ConnectAccounts {
	font-family: Roboto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 100%;
	min-height: 100vh;
	background-image: url("../images/bg.png");
	padding-top: 120px;

	.ModalMaster {
		.Modal {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
			display: flex;
			align-items: center;
			justify-content: center;
			visibility: hidden;
			opacity: 0;
			//    transition: 200ms ease all;
			overflow-x: hidden;
			background: rgba(0, 0, 0, .5);

			.Modal__layer {
				// background: rgba(0,0,0,0.5);
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				cursor: pointer;
			}

			align-items: flex-start;

			@media screen and (min-width: 640px) {
				align-items: center;
			}

			.Modal__wrapper {
				background-color: #322D38;
				position: relative;
				text-align: center;
				align-items: center;
				justify-content: center;
				transform: scale(0.8);
				margin: 5vw 0 5vh;

				@media screen and (min-width: 640px) {
					margin: 0;
				}

				@media screen and (max-width: 640px) {
					max-height: 2000px !important;
					padding-bottom: 50px !important;
					height: auto !important;
				}

				.icon {
					width: 50px;
					margin: 30px auto;

					img {
						width: 100%;
					}
				}

				.Modal__title {
					font-size: 25px;
					letter-spacing: 2px;
					margin: 20px 0 0;
				}

				.Modal__icon {
					width: 100%;
					height: 80px;
					background-color: #211C27;
					display: flex;
					align-items: center;
					justify-content: space-around;

					.logo {
						align-self: center;
						background-repeat: no-repeat;
						background-size: contain;
						height: 46px;
						width: 122px;
					}

					@media #{$mobileOnly} {
						width: 100%;
						height: 8vh;
					}

					.Modal__close-btn {
						width: 35px;
						height: 35px;
						margin-left: auto;
						position: absolute;
						right: 30px;
						top: 15px;
						cursor: pointer;

						&:before {
							content: "";
							width: 3px;
							height: 90%;
							background: #37393D;
							display: block;
							position: absolute;
							left: 50%;
							// top: 50%;
							transform-origin: center;
							transform: rotate(45deg);
						}

						&:after {
							content: "";
							width: 3px;
							height: 90%;
							background: #37393D;
							display: block;
							position: absolute;
							left: 50%;
							// top: 50%;
							transform-origin: center;
							transform: rotate(-45deg);
						}
					}

				}


				.form__message {
					color: #83858c;
					font-size: 12px;
					margin-top: 15px;
					text-align: left;
				}

				.form__footer {
					padding-top: 60px;

					.form__footer__left {
						padding-left: 0;
						text-align: left;

						.cta-button {
							background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
							color: #fff;
							cursor: pointer;
							text-decoration: none;
							text-align: center;
							font-weight: normal;
							border-radius: 4px;
							font-weight: 900;
							font-size: 16px;
							padding: 20px 40px;
						}
					}

					.form__footer__right {
						font-size: 12px;
						padding-top: 10px;

						a {
							color: #3a74e0;
							font-weight: 600;
						}
					}

					.form__footer__right__2 {
						font-size: 12px;
						padding-top: 20px;

						button {
							color: #3a74e0;
							cursor: pointer;
							font-weight: 600;
						}
					}
				}

				.form__footer__2 {
					width: 40%;
				}

				.img-left {
					float: left;
					width: auto; // to maintain aspect ratio. You can use 100% if you don't care about that
					height: 100%;
				}

				.errors-container {
					display: flex;
					color: #dc4f4f;
					font-size: 13px;
					text-align: left;
					padding: 0px 25px 0px 10px;
					border-radius: 1px;
					border: 1px solid #dc4f4f;
					position: relative;
					margin: 0;
					background: rgba(255, 255, 255, 0.01);
					align-items: center;
					visibility: hidden;
					opacity: 0;
					max-height: 0;
					overflow: hidden;
					transition: ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms padding, ease 300ms margin;

					&.active {
						visibility: visible;
						opacity: 1;
						max-height: inherit;
						min-height: 35px;
						padding: 5px 25px 5px 10px;
						margin: 0 0 30px 0;
					}

					&:hover {
						background: rgba(255, 255, 255, 0.03)
					}

					.error-dismiss {
						position: absolute;
						width: 18px;
						height: 18px;
						border-radius: 100%;
						top: 8px;
						right: 10px;
						background: rgba(220, 79, 79, 0.22);
						cursor: pointer;
						outline: none;
						transition: ease 200ms all;

						&::before {
							content: '';
							height: 1px;
							width: 10px;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%) rotate(-45deg);
							background: rgba(255, 255, 255, 0.5);
							transition: ease 200ms all;
						}

						&::after {
							content: '';
							height: 1px;
							width: 10px;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%) rotate(45deg);
							background: rgba(255, 255, 255, 0.5);
							transition: ease 200ms all;
						}

						&:hover {
							background: rgba(220, 79, 79, 0.5);

							&::before {
								background: rgba(255, 255, 255, 0.7);
							}

							&::after {
								background: rgba(255, 255, 255, 0.7);
							}
						}
					}
				}
			}

			&.active {
				visibility: visible;
				opacity: 1;

				.Modal__wrapper {
					transform: scale(1);
					//transition: 200ms ease all;
				}
			}
		}

		.Modal__welcome {
			.Modal__wrapper {
				height: 100%;
				width: 900px;

				.Welcome__header {
					margin: 70px auto 130px;
					color: #F4F4F4;
					font-size: 24px;
					text-transform: uppercase;
					border-bottom: 2px solid transparent;
					border-image: linear-gradient(to right,
							transparent 25%,
							#FA3633 25% 75%,
							transparent 75%);
					border-image-slice: 1;
					width: fit-content;
				}

				.Welcome__footer {
					margin: 180px 0 auto;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					button,
					a {
						display: block;
						background-color: #EF3937;
						color: white;
						cursor: pointer;
						font-weight: bold;
						text-decoration: none;
						text-align: center;
						border-radius: 26px;
						font-size: 18px;
						padding: 10px 100px;
						line-height: 24px;
						text-transform: uppercase;

						&.disabled {
							cursor: disabled;
							background: #2e2f32;
							color: $colorGray;
						}
					}

					a.logout {
						padding: 10px 0;
						display: none;
						background-color: transparent !important;
						text-decoration: none;
						text-align: center;
						font-weight: normal;
						font-size: 14px;
						display: block;
						color: rgba(255, 255, 255, 0.3);

						&:hover {
							cursor: pointer !important;
						}
					}
				}

				.blurb {
					color: #595261;
					width: 100%;
					font-size: 16px;
					bottom: 34px;
					position: absolute;
				}
			}
		}
	}
}

.loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
}

.loading-spinner {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;

	i {
		margin-bottom: 20px;
	}

	span {
		font-size: 18px;
	}
}