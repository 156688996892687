@import '../../utils/_variables';
$colorCyan: #49e1ca;
$colorCyan2: #21CDB9;
$colorGray: #83858c;
$colorDarkGray: #313337;
$colorBGray: #212227;
$colorBlue: #1378E1;
$colorRed: #FA5053;

.MyQuests {
  a {
    text-decoration: none;
  }

  .tooltip {
    color: white;
    display: inline-block;
    position: relative;
  }

  .tooltiptext {
    font-size: 10px;
  }

  .tooltip .tooltiptext {
    background-color: $colorBackground;
    border-radius: 6px;
    color: #fff;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 25px;
    visibility: hidden;
    width: 200px;
    z-index: 100;

    &:before {
      background-color: $colorBackground;
      border-left: solid 1px $colorBackground;
      border-top: solid 1px $colorBackground;
      content: "";
      height: 11px;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      right: 0;
      right: 100px;
      top: -4.5px;
      transform: rotate(45deg);
      width: 11px;
      z-index: 100;
    }
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .ModalMaster {

    .NoGames.sidebar,
    .NoGames.sidebari {
      color: #fff !important;
      display: block;
      padding: 10px 20px;

      a,
      a:visited,
      a:focus {
        color: #fff !important;
      }
    }

    .Modal {
      align-items: center;
      align-items: flex-start;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      overflow: hidden;
      position: fixed;
      top: 0;
      transition: 300ms ease all;
      visibility: hidden;
      width: 100%;
      z-index: 99;

      &.active {
        transition: 300ms ease all;
        visibility: visible;

        .Modal__layer {
          opacity: 1;
          transition: 300ms ease all;
          visibility: visible;
        }

        .Modal__wrapper {
          transform: translateX(0%);
          transition: 300ms ease all;
        }
      }

      .Modal__layer {
        background: rgba(0, 0, 0, .5);
        cursor: pointer;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: 300ms ease all;
        visibility: hidden;
        width: 100%;
      }

      .Modal__wrapper {
        align-items: center;
        background: #211C27;
        color: white;
        height: 100%;
        justify-content: center;
        max-width: 450px;
        padding: 0;
        position: absolute;
        right: 0;
        text-align: left;
        transform: scale(0.8);
        transform: translateX(100%);
        transition: 300ms ease all;
        width: 90%;

        @media screen and (min-width: 640px) {
          margin: 0;
        }

        .MyQuests__connectTwitch {
          font-size: 12px;
          margin-top: 10px;
          padding: 0 20px;

          i {
            font-weight: 300;
          }

          a {
            color: $colorEnergyBlue;
            font-weight: 600;
          }
        }

        .padding_myquests {
          padding-left: 20px;
          padding-right: 20px;
        }

        .quest_gradient {
          -webkit-mask-image: -webkit-gradient(linear, left top, right top,
              color-stop(0.00, rgba(0, 0, 0, 1)),
              color-stop(0.35, rgba(0, 0, 0, 1)),
              color-stop(0.50, rgba(0, 0, 0, 0.5)),
              color-stop(0.86, rgba(0, 0, 0, 0.02)),
              color-stop(0.87, rgba(0, 0, 0, 1)),
              color-stop(1.00, rgba(0, 0, 0, 1)));
          mask-image: -webkit-gradient(linear, left top, right top,
              color-stop(0.00, rgba(0, 0, 0, 1)),
              color-stop(0.35, rgba(0, 0, 0, 1)),
              color-stop(0.50, rgba(0, 0, 0, 0.5)),
              color-stop(0.86, rgba(0, 0, 0, 0.02)),
              color-stop(0.87, rgba(0, 0, 0, 1)),
              color-stop(1.00, rgba(0, 0, 0, 1)));
        }

        .Modal_title {
          @extend .padding_myquests;
          border-bottom: 3px solid #8B8A90;
          font-size: 24px;
          font-weight: 700;
          margin-top: 30px;
          padding-bottom: 10px;
          text-transform: uppercase;
        }

        .Modal_body {
          .Modal_body__tabs {
            display: flex;
            list-style: none;
            border-bottom: 2px solid #3A3440;
            margin: 30px 0;
            gap: 10px;

            li {
              padding: 0 20px;
              font-family: Arial;
              font-weight: 400;
              font-size: 14px;
              color: #DEDCE0;
              text-transform: uppercase;
              cursor: pointer;
            }

            .active {
              border-bottom: 2px solid #EF3937;
            }
          }

          .QuestBook {
            height: calc(100vh - 150px);
            opacity: 0;
            overflow: hidden;
            transition: 300ms ease all;
            visibility: hidden;
            width: 103%; // solves mystery padding issue

            &.active {
              display: block;
              opacity: 1;
              overflow-y: scroll;
              transition: 300ms ease all;
              visibility: visible;
            }

            &.active::-webkit-scrollbar {
              width: 10px;
            }

            &.active::-webkit-scrollbar-thumb {
              background-color: #83858c;
              border-radius: 10px;
              outline: 1px solid #83858c;
            }

            .QuestByStatus {
              padding-left: 20px;
              width: 100%;

              .QuestByStatus__title {
                color: $colorGray;
                font-size: 12px;
                font-weight: bold;
                margin-bottom: 13px;
              }

              .QuestByStatus__item {
                // align-items: center;
                background-color: transparent;
                border: none;
                cursor: pointer;
                display: flex;
                justify-content: center;
                margin: 0;
                margin-bottom: 20px;
                min-height: 110px;
                outline: none;
                padding: 0;
                position: relative;
                text-transform: inherit;
                transition: 400ms ease all;
                width: 100%;

                .QuestByStatus__item--cover {
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  height: 110px;
                  left: 0;
                  margin: 0;
                  padding: 0;
                  position: absolute;
                  top: 0;
                  width: 168px;
                }

                .QuestByStatus__item--details {
                  align-items: flex-start;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-evenly;
                  margin-left: 180px;
                  width: 70%;

                  .QuestByStatus__item--details-type {
                    i {
                      color: $colorGray;
                      font-size: 16px;
                      margin-right: 5px;
                    }

                    .type {
                      color: white;
                      font-size: 14px;
                      font-weight: 700;
                      line-height: 15px;
                      text-transform: capitalize;
                      margin: 15px 0;
                    }

                    .bounty {
                      color: $colorCyan;
                      font-size: 13px;
                      font-weight: 700;
                      line-height: 15px;
                      margin-left: 5px;
                    }
                  }

                  .QuestByStatus__item--details-date {
                    color: $colorGray;
                    font-size: 14px;
                    font-weight: bold;
                    margin: 5px 0
                  }

                  .QuestByStatus__item--details-title {
                    color: white;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis; // 添加省略号
                    word-break: break-word; // 确保长单词会换行
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.07;
                    margin-right: 10px;
                    text-align: left;
                  }

                  .QuestByStatus__item--details-requirements {
                    font-size: 16px;
                    font-weight: bold;
                    margin-top: 5px;
                    text-align: left;

                    &.success {
                      color: $colorCyan;
                    }

                    &.failure {
                      color: #ff0000;
                    }

                    &.underReview {
                      color: #a142ff;
                    }
                  }
                }

                &:hover .QuestByStatus__item--details-fullTitle {
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
  }

  .QuestBook__noiz-disclaimer {
    color: #cbe1ef;
    font-size: 7pt;
    font-style: italic;
    text-justify: newspaper;
  }
}

// 添加到 MyQuests.scss 文件中

// 类别标题
.category-title {
  color: #a0a0a0;
  font-size: 14px;
  margin: 24px 0 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  &:first-child {
    margin-top: 0;
  }
}

// Leaderboard 任务样式
.QuestByStatus__item.leaderboard-quest {
  position: relative;
  border-left: 3px solid #47BCD3;

  &:hover {
    border-left-color: #6bd4e9;
  }

  .leaderboard-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(71, 188, 211, 0.2);
    color: #47BCD3;
    font-size: 11px;
    font-weight: bold;
    padding: 3px 8px;
    border-radius: 4px;
    text-transform: uppercase;
  }

  .QuestByStatus__item--details {
    .QuestByStatus__item--details-rank {
      font-size: 13px;
      color: #a0a0a0;
      margin-top: 6px;

      .rank-number {
        font-weight: bold;
        color: #ffffff;
      }
    }

    .QuestByStatus__item--details-prize {
      font-size: 13px;
      color: #a0a0a0;
      margin-top: 3px;

      .prize-amount {
        font-weight: bold;
        color: #47BCD3;
      }
    }
  }
}