@import '../../../utils/_variables';

$tablet: 1300px;
$tabletOnly: '(max-width: #{$tablet})';

@keyframes animateIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}


@keyframes animateOut {
  0% {
    display: block;
    opacity: 1;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: none;
    opacity: 0;
  }
}


@keyframes slideOver {
  0% {
    left: 0;
    width: 0%;
  }

  50% {
    width: 100%;
  }


  50% {
    width: 100%;
  }
}


.MuiTooltip-tooltip {
  background: #2B2E38 !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  font-size: 12px !important;
  color: white !important;
  padding: 0px 13px !important;

  a {
    color: #3a74e0;
  }
}

html {
  scroll-behavior: smooth;
}

.QuestDetailPage {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .BottomGradient {
    width: 100%;
    height: 200px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), $colorDarkGray);
  }

  // Buttons
  button {
    min-width: 100px;
    background: #EF3937;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    border-radius: 24px;
    padding: 0 36px;
    font-weight: 700;
    font-size: 16px;
    margin: 0 0 0 20px;
    height: 42px;
    text-transform: uppercase;

    &.download {
      width: 200px;
      margin: 25px 0 0 0;
    }

    &.edit,
    &.leave {
      background: none;
      border: 2px solid #595261;
      -moz-transition: all .25s ease-in;
      -o-transition: all .25s ease-in;
      -webkit-transition: all .25s ease-in;
      transition: all .25s ease-in;

      &:hover {
        border: 2px solid #808280;
        background-color: rgba(58, 116, 224, 0.25);
      }
    }

    &.leave-quest {
      width: 160px;

      @media #{$mobileOnly} {
        width: 100%;
        margin: auto;
      }
    }

    &.left {
      margin: 20px 0 30px 0;


      background: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
    }

    @media #{$mobileOnly} {
      font-size: 14px;
      min-width: 80px;
      height: 40px;
      margin-left: 8px;
      padding: 0;
    }
  }

  .QuestDetailPage__container1 {
    display: flex;
    flex-direction: column;
    padding: 20px 200px 50px;
    display: flex;
    border-radius: 10px;

    .noQuestFound {
      font-size: 28px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 100px 0 0 0;
      text-align: center;
    }

    @media #{$mobileOnly} {
      width: auto;
      margin: 0 12px;
    }

    .missingRequirementsBar {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 3px;
      background-image: linear-gradient(to right, #fd8d82 0%, #6236ff 100%);
      align-items: center;
      padding: 24px 0;

      span {
        font-size: 16px;
        text-align: center;
        margin: auto;
      }

      ul {
        max-width: 600px;
        margin: 14px 0 0 0;

        li {
          font-size: 16px;
          text-decoration: underline;
          font-weight: bold;
          cursor: pointer;
        }

        @media #{$mobileOnly} {
          max-width: 250px;
        }

      }
    }
  }

  .QuestDetailPage__inContainer {
    .sidebar {
      min-width: 280px;
      background: #2C2733;
      box-sizing: border-box;
      display: flex;
      border-bottom: 2px solid #EF3937;
      /* Firefox */
      // scrollbar-color: #ffffff transparent;

      /* Webkit */
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        border-radius: 4px;
      }
    }


    ul.nav-tabs {
      display: flex;
      margin-block: 14px;
      border-bottom: none;
      gap: 10px;
      padding-left: 24px;

      @media #{$mobileOnly} {
        margin-bottom: 40px;
      }

      li {
        display: inline-block;
        font-size: 24px;
        width: 100%;

        a {
          display: inline-block;
          background: #595261;
          padding: 8px 25px;
          border-radius: 32px;
          text-transform: inherit;
          transition: 300ms ease background;
          position: relative;
          cursor: pointer;
          z-index: 1;
          font-size: 14px;
          text-decoration: none;
          color: #fff;

          &.nav-tab:after {
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            bottom: 8px;
            height: 4px;
            z-index: -1;
            transition: 200ms ease all;
          }

          &.nav-tab:hover {
            color: #fff;
            background-color: #EF3937;
          }

          &.nav-tab.active {
            width: 100%;
            color: #fff;
            background-color: #EF3937;
          }
        }
      }

      li a.active {
        color: white;
      }

      li a:hover {
        color: white;
      }
    }


  }

  .Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    overflow-x: hidden;
    background: rgba(0, 0, 0, .5);
    align-items: flex-start;
    display: flex;
    align-items: center;

    &.active {
      visibility: visible;
      opacity: 1;

      .Modal__layer {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
      }

      .Modal__wrapper {
        width: 600px;
        max-width: 825px;
        background: #322D38;
        padding: 0;
        position: relative;
        transform: scale(0.8);
        top: -50%;
        transform: scale(1) translateY(calc(50% + 30vh));
        margin: 10vh 0;
        border-radius: 10px;
        padding: 40px 40px 20px;
        border-bottom: 3px solid #EF3937;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.LeaveQuest {
          min-height: 225px;

          h1 {
            font-family: Arial;
            font-size: 24px;
            color: #FDFBFF;
            text-align: center;
          }

          .subtitle {
            font-family: Arial;
            font-size: 16px;
            color: #FDFBFF;
            margin-bottom: 20px;
            text-align: left;
          }

          .reason_title {
            font-family: Arial;
            font-size: 16px;
            color: #FDFBFF;
            margin-bottom: 4px;
          }

          .textBlock {
            width: 100%;
            background: #211C27;
            border-radius: 8px 8px 8px 8px;
            border: none;
            color: #FDFBFF;
            font-size: 14px;
          }

          .buttonGroup {
            margin: 40px auto 0;
          }

          i.close_icon {
            font-size: 20px;
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .StatusBarContainer {
    .StatusBar {
      height: 68px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none !important;
      background: #211C27;
      border-radius: 10px;
      z-index: 10;
      margin-bottom: 0px;

      &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        background: $colorBDarkGray;
        padding: 0 5%;
      }

      #status-bar-content-mobile {
        height: 0px;
        overflow: hidden;
      }
    }

    .left {
      display: flex;
      align-items: center;
      min-width: 50%;
    }

    h2 {
      margin-right: 20px;
      font-size: 20px;
    }

    i {
      color: $colorLightGray;
      margin: 10px;
    }

    .status {
      font-size: 12px;
      font-weight: 700;
      margin: 0 20px;
      letter-spacing: 1px;

      &.error {
        color: red;
      }

      &.completed {
        color: $colorLifeGreen;
      }
    }

    .payment {
      margin: 0 20px;
    }

    .paymentContainer {
      align-items: center;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.25));
      height: 68px;
      display: flex;
      justify-content: space-evenly;

      h3 {
        font-size: 20px;
      }


      &.freeKey {
        h3 {
          font-size: 15px;
        }
      }
    }

    .streamingPlatform {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin: 0 20px;
      height: auto;

      svg {
        height: 24px;
      }

      .fa-twitch {
        font-size: 24px;
      }
    }

    @media #{$mobileOnly} {
      .StatusBar {
        justify-content: center;
        flex-direction: column;
        height: auto;
        align-items: normal;
      }

      h2 {
        text-align: center;
        margin: 10px 0 15px 0;
      }

      img {
        margin: 10px auto;
        color: rgba(255, 255, 255, 0.5);
      }

      .paymentContainer {
        width: 76px;
        width: 76px;
        height: 43px;
        font-size: 20px;

        .payment {
          margin: 0 6px;
        }
      }

      .streamingPlatform {
        font-size: 14px;
        margin: 5px 0 0 10px;

        svg {
          height: 18px;
        }

        .fa-twitch {
          font-size: 18px;
        }
      }

      .status {
        font-size: 10px;
        margin: 20px 20px;
        text-align: center;
      }
    }
  }

  .NavScrollContainer {
    @media #{$tabletOnly} {
      display: none;
    }
  }

  .QuestDetailPage__scrollContainer {
    display: flex;
    margin-right: auto;
    background-color: #211C27;
    padding: 24px;

    @media #{$mobileOnly} {
      padding-top: 0px;
    }

    .NavContainer {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 215px);
      padding: 0 2vw 0 0;

      &.sticky {
        position: fixed;
      }

      &.stop {
        position: absolute;
      }

      .streamSection {
        h2 {
          margin: 0 0 0 15px;
          display: flex;
          align-items: center;
        }
      }

      ul {
        list-style: none;
        padding-left: 65px;

        li {
          color: $colorLightGray;
          margin: 15px 0px;
          font-weight: 600;
          display: flex;
          align-items: center;

          .navItem {
            text-decoration: none;
            color: $colorLightGray;
            font-size: 14px;
            cursor: pointer;

            &.rejected {
              color: red;
            }
          }

          &.active {
            color: $colorPurpleRay;

            .navItem {
              color: $colorPurpleRay;
            }
          }

          i {
            color: red;
          }
        }
      }

      span {
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        text-align: center;
        padding-left: 17px;
        font-size: 20px;
        font-weight: 900;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 50%;

        &.active {
          background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6 31%, #5d69ea 67%, #8358ed);
        }
      }

      h2 {
        span {
          padding: 20px;

          &.active {
            background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6 31%, #5d69ea 67%, #8358ed);
            border-radius: 50%;
            height: 48px;
            width: 48px;
            display: inline-block;
          }
        }
      }
    }

    .ReqContainer {
      width: 100%;
      display: flex;
      flex-direction: column;

      .ReqContainer_items {
        border-bottom: 2px solid #EF3937;
        padding: 24px;
      }

      h1 span {
        display: 'inline-block';
        background-color: #433C4A;
        font-size: 18px;
        color: #fff;
        padding: 2px 10px;
        border-radius: 8px;
        margin-right: 20px;
        text-align: center;
        height: 40px;
        position: relative;
        top: -3px;

      }

      .no_req {
        text-align: center;
        font-size: 16px;
        padding: 50px;
      }

      .update_message {
        color: #888;
        font-size: 14px;
        margin-bottom: 15px;
      }

      .platform-indicator {
        display: flex;
        align-items: center;
        width: 100%;
        background-color: #2C2733;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 30px;

        .platform-icon-wrapper {
          width: 44px;
          height: 44px;
          padding: 8px;
          border-radius: 8px;
          background-color: #1B1C26;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 18px;
            height: 18px;
            fill: #ffffff;
          }
        }

        .platform-name {
          font-size: 18px;
          margin: 0 0 0 10px;
          color: #ffffff;
          font-weight: bold;
        }
      }

      .ReqContainer_item {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #2C2733;
        border-radius: 8px;
        padding: 0 20px 20px;
        margin-bottom: 30px;

        &__title {
          color: #ffffff;
        }

        .reward_item {
          margin-bottom: 20px;
          padding: 15px;
          border-radius: 6px;
          background-color: #1B1C26;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: background-color 0.3s, border-color 0.3s;

          &.achieved {
            background-color: rgba(0, 240, 255, 0.1);
            // border-color: #ffffff;
            border: #ffffff 1px solid;
          }

          .achieved_color {
            color: #ffffff;
          }

          .item__info {
            .info__name {
              font-weight: bold;
              color: #ffffff;
              font-size: 18px;
            }

            .info__requirement {
              font-size: 14px;
              color: #888;
              line-height: 1.4;
              align-items: center;
            }
          }

          .item__status {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 16px;
          }

        }

        .rank-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          font-size: 36px;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          margin-bottom: 15px;

          .estimated-earnings {
            text-align: left;
            padding: 15px;
            background-color: #1B1C26;
            border-radius: 6px;
            margin-top: 10px;
            width: 100%;
            display: flex;
            align-items: center;

            .earnings-label {
              flex: 1;
              font-size: 14px;
              color: #888;
              margin-bottom: 5px;
            }

            .earnings-value {
              flex: 1;
              font-size: 36px;
              font-weight: bold;
              color: #EF3937;
            }
          }
        }

        .overview-grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 30px;

          .overview-item {
            text-align: center;

            .overview-value {
              font-size: 28px;
              font-weight: bold;
              margin-bottom: 5px;
            }

            .overview-label {
              font-size: 14px;
              color: #888;
            }
          }
        }
      }

      .achieved {
        background-color: rgba(0, 240, 255, 0.1);
        border-color: #ffffff;
      }

      h1 {
        margin: 30px 0 10px 0;
      }


      @media #{$mobileOnly} {

        h1 {
          text-align: center;
          font-size: 20px;
        }
      }

      .CommissionBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: 104px;
        border-radius: 12px;
        background-color: rgba(0, 0, 0, 0.35);
        width: 100%;
        padding: 20px;

        @media #{$mobileOnly} {
          flex-direction: column;
          text-align: center;
          padding: 12px;
        }

        h2 {
          font-size: 20px;
          margin: 0 0 14px 0;
        }

        .static-content {
          display: flex;
          flex-direction: column;
          padding: 0 20px 0 0;
        }

        .variable-content {
          display: flex;
          flex-direction: row;
          align-items: flex-end;

          @media #{$mobileOnly} {
            flex-direction: column;
          }

          .values {
            display: flex;
            flex-direction: row;

            @media #{$mobileOnly} {
              align-self: center;
            }
          }

          .trackingLink {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            margin: 0 0 0 12px;

            .label {
              text-transform: uppercase;
              font-size: 12px;
              letter-spacing: 1px;
              color: #ffffff;
              font-weight: bold;
              margin-top: 18px;
            }

            input {
              height: 44px;
              background-color: #202227;
              color: #ffffff;
              border-radius: 3px;
              border: solid 2px #2c3239;
              margin: 10px 0 0 0;
              padding: 0px;
              padding: 0px 10px;
              max-width: 296px;
              min-width: 200px;
            }
          }

          @media #{$mobileOnly} {
            .labelLink {
              text-align: left;
            }

            .label {
              font-size: 10px;
            }

            input {
              font-size: 12px;
            }

            .trackingLink {
              margin: 20px auto 0px auto;
            }
          }
        }
      }

      .valueTrack {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        min-width: 64px;
        height: 64px;
        border-radius: 3px;
        border: solid 1px #2c3239;
        background-color: #202227;
        margin: 17px 12px 0 0;
        padding: 4px 12px;

        .count {
          font-size: 30px;
          font-weight: 900;
          margin: 0;
        }

        .description {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          color: $colorGray;
          letter-spacing: 1px;
        }
      }

      .RequirementContainer {
        position: relative;

        .requirementsCheckbox {
          float: left;
          padding: 0 10px;
        }

        .timeline {
          height: 100%;
          display: block;
          position: absolute;
          width: 40px;

          .topBar {
            margin: 35px 0 0 0;
            width: 40px;
            height: 15px;
            background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 19px, rgba(131, 133, 140, 1) 19px, rgba(131, 133, 140, 1) 20px, rgba(0, 0, 0, 0) 20px, rgba(0, 0, 0, 0) 100%);

            &.first {
              background-image: none;
            }
          }

          .bottomBar {
            margin: 22px 0 0 0;
            width: 40px;
            height: calc(100% - 20px);
            background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 19px, rgba(131, 133, 140, 1) 19px, rgba(131, 133, 140, 1) 20px, rgba(0, 0, 0, 0) 20px, rgba(0, 0, 0, 0) 100%);

            &.last {
              background-image: none;
            }
          }

          @media #{$mobileOnly} {
            width: 20px;

            .requirementsCheckbox {
              margin: 0px 0 0 2px;
              padding: 0px;
            }

            .topBar {
              margin: 25px 0 0 0;
              background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 7px, rgba(131, 133, 140, 1) 7px, rgba(131, 133, 140, 1) 8px, rgba(0, 0, 0, 0) 8px, rgba(0, 0, 0, 0) 100%);
            }

            .bottomBar {
              margin-top: 12px;
              background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 7px, rgba(131, 133, 140, 1) 7px, rgba(131, 133, 140, 1) 8px, rgba(0, 0, 0, 0) 8px, rgba(0, 0, 0, 0) 100%);
            }

            img.checkbox {
              width: 12px;
            }
          }
        }
      }

      .collapsed_content1 {
        background: #2C2733;
        padding: 24px;
      }

      .RequirementBox {
        background: #433C4A;
        display: flex;
        margin: 20px 0px 0px 0;
        border-radius: 12px 12px 0 0;
        overflow: hidden;
        padding: 12px 24px;
        align-items: center;

        .RequirementBackground {
          top: 0;
          bottom: 0;
          position: absolute;
          width: 0%;
          left: 0;
          right: 0;
          margin: 20px 0px 0px 64px;
          background: linear-gradient(90deg, black 0px, black 80px, #202227 80px, #202227 100%);
          opacity: 1;
          z-index: 1;
          border-radius: 12px;
        }

        &.in-review {
          background-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 0px, rgba(0, 0, 0, 1) 80px, rgba(32, 34, 39, 1) 80px, rgba(32, 34, 39, 1) 100%);

          @media #{$mobileOnly} {
            background: rgba(32, 34, 39, 1);
          }
        }

        @media #{$mobileOnly} {
          background: rgba(0, 0, 0, 0.35);
          margin: 20px 0px 0px 25px;
        }

        span {
          font-size: 16px;
        }

        &.rejected {
          border: 1px solid red;

          span.note {
            color: red;
          }
        }

        .icon {
          border-radius: 50%;
          background: $colorBDarkGray;
          min-width: 54px;
          height: 54px;
          display: flex;
          align-content: center;
          justify-content: center;
          margin: 8px 14px 0 14px;
          z-index: 2;

          img {
            width: 30px;
          }

          @media #{$mobileOnly} {
            min-width: 40px;
            height: 40px;
            margin: 8px 0px 0 8px;

            img {
              width: 20px;
            }
          }
        }

        .imageIcon {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          z-index: 2;
          margin: 8px 14px 0 14px;

          &.schedule {
            width: 50px;
            margin: 10px 15px 10px 15px;
            height: 50px;

            @media #{$mobileOnly} {
              width: 30px;
              background-size: contain;
              background-repeat: no-repeat;
              height: 30px;
              transform: translateX(5px) translateY(12px);
              margin: 0px;
            }
          }

          @media #{$mobileOnly} {
            width: 48px;
            height: 48px;
            min-width: 48px;
          }
        }

        span.content-right {
          margin: 27px 16px 20px auto;
          display: flex;
          flex-direction: row;
          z-index: 2;

          .collapseArea {
            position: absolute;
            width: 50px;
            height: 50px;
            right: 10px;
          }

          img.collapseToggle {
            width: 16px;
            height: 8px;
            margin: 9px 4px 0px 0px;
            // display: none;
            opacity: 0;
          }

          .toolTip {
            color: #3a74e0;
            border: 2px solid #3a74e0;
            border-radius: 50%;
            min-width: 25px;
            height: 25px;
            text-align: center;
            font-weight: 600;
            margin: 0 0 0 20px;
            opacity: 1;
          }

          @media #{$mobileOnly} {
            display: none;
          }

          &.collapseable {
            .toolTip {
              animation: animateOut 1s ease-out;
              opacity: 0;
              display: none;
            }

            img.collapseToggle {
              animation: animateIn 1s ease-out;
              opacity: 1;
              display: block;
            }
          }
        }



      }

      .content {
        display: flex;
        flex-direction: column;
        z-index: 2;
        font-size: 18px;
        .label {
          text-transform: uppercase;
          letter-spacing: 1px;
          color: #ffffff;
          font-weight: bold;
        }
        .description {
          p {
            margin: 0;
          }
        }
        h2 {
          margin: 0;
        }
        span {
          color: #ffffff;
          margin: 25px 0 0 0;
          color: rgba(255, 255, 255, 0.9);
          &.reviewStatus {
            color: $colorPurpleRay;
            margin: 0 0 0 20px;
            width: 190px;
            &.error {
              color: red;
            }
            @media #{$mobileOnly} {
              display: none;
            }
          }

          &.reviewStatus-mobile {
            color: $colorPurpleRay;
            margin: 15px 0 0 -47px;
            width: 190px;

            &.error {
              color: red;
            }

            @media #{$tabletAndLarger} {
              display: none;
            }
          }

          &.error {
            color: red;


            @media #{$mobileOnly} {
              width: 80%;
            }
          }
        }

        @media #{$mobileOnly} {
          padding: 16px 12px;
          max-width: 90%;

          h2 {
            font-size: 16px;
          }

          span {
            font-size: 12px;
            // margin: 15px 0 0 -45px;

            &.description {
              margin: 25px 0 0 -45px;
            }
          }

          .label {
            font-size: 10px;
            margin-left: -45px;
          }
        }

        .timeBox {
          display: flex;
          flex-direction: row;

          @media #{$mobileOnly} {
            margin: 0 0 0 -45px;
          }
        }

        .timeStatus {
          display: flex;
          flex-direction: row;
          align-items: baseline;

          @media #{$mobileOnly} {
            margin: 0 0 0 -45px;
          }

          span {
            margin: 10px 5px 0 0 !important;

            &.indicator {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: $colorGray;
              margin: 0 10px 0 0;

              &.complete {
                background: $colorLifeGreen;
              }
            }
          }
        }

        .copyContent {
          display: flex;
          align-items: center;
          width: 600px;
          margin: 10px 0 0 0;

          input {
            height: 44px;
            background-color: #202227;
            color: #ffffff;
            border-radius: 3px;
            border: solid 2px #2c3239;
            margin: 0px;
            padding: 10px;
            max-width: 296px;
            min-width: 200px;
            font-size: 14px;

            @media #{$mobileOnly} {
              width: 215px;
            }

            &.error {
              border: solid 1px red;
            }

            &:focus {
              background-color: #202227 !important;
            }

            &::placeholder {
              color: #83858c;
            }
          }

          @media #{$mobileOnly} {
            font-size: 12px;
            width: 400px;
            margin: 10px 0 0 -50px;
          }

          .content-media-selector {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  .uploading-progress {
    display: flex;
    margin: auto 0;
    width: 200px;
    min-width: 200px;
    height: 100px;
    min-height: 100px;
    content: "";
  }
}