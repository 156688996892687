@import '../../utils/_variables';

.Quest__step--details-description {
  a {
    color: #83858c !important;
  }
}

.Game {
  padding: 80px 0 0 0;

  .Game__background {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;

    figure {
      margin: 0;
      width: 100%;
      height: 100vh;
      background-position: center center;
      background-size: cover;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;

      background: linear-gradient(to bottom, #18191d 0%, rgba(24, 25, 29, .20) 35%, rgba(21, 11, 4, 0.9) 75%, #18191d 100%);
    }
  }

  .Game__container {

    .GameInfo {
      display: flex;
      flex-direction: row;
      align-items: center;
      // max-width: 1024px;
      margin: 0 auto;
      padding: 44px 180px;
      background-color: $colorMainBackgroud;
      // border-bottom: 1px solid rgba(255, 255, 255, 0);
      // padding: 40px;
      position: relative;

      @media screen and (max-width: 767px) {
        padding: 30px 0 0 0 !important;
      }

      .GameInfo__image {
        border-radius: 8px;
        width: 380px;
        height: 420px;
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0;
        z-index: 15;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.66);
        /* Right shadow */

        @media screen and (max-width: 767px) {
          margin: auto !important;
        }

        &:before {
          content: "";
          margin-top: 145%;
          display: block;
        }
      }

      .GameInfo__details {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        height: 360px;
        padding: 40px;
        background-color: $colorMainBlack;
        border-radius: 8px;
        margin-left: -5px;

        @media screen and (max-width: 767px) {
          position: initial !important;
          width: 100% !important;
          padding: 50px;

          // .GameInfo__details--title {
          //   font-size: 14px !important;
          // }

          // .GameInfo__details--bottom {
          //   margin-top: 0 !important;
          //   flex-direction: column !important;

          // }
        }

        .GameInfo__details--title {
          color: $colorMainOrange;
          font-size: 16px;
          font-weight: 400;
          font-family: "arial";
          margin: 0;
        }

        .GameInfo__details--content {
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          font-family: "arial";
          margin: 0;
        }

        .parsed-content {
          font-size: 12px; // Set the font size to 12px for the parsed content
        }

        .GameInfo__details--top {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
        }

        .GameInfo__details--bottom {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr); // Create a grid with 4 columns
          gap: 20px; // Add space between grid items

          .GameInfo__details--item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            font-family: "arial";
            margin: 0;

            .title {
              color: $colorMainOrange;
              font-size: 15px;
              font-weight: 400;
              font-family: "arial";
              margin: 0;
            }
          }
        }
      }

      .GameInfo__cdkey {
        margin-left: -10px;
        z-index: 1;
        border-radius: 8px;
        height: 360px;
        background-color: $colorMainOrange;

        .GameInfo__cdkey--wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 40px 30px;
          color: #fff;
          font-family: Arial, Helvetica, sans-serif;

          .GameInfo__cdkey--tag1 {
            font-size: 30px;
            font-weight: 600;
          }

          .GameInfo__cdkey--tag2 {
            font-size: 30px;
            font-weight: 600;
            white-space: nowrap; // Prevent text from wrapping to the next line
            overflow: hidden; // Hide any overflow text
            text-overflow: ellipsis; // Add ellipsis (...) for overflow text
          }

          .GameInfo__cdkey--remaining {
            font-size: 16px;
            font-weight: 500;
            white-space: nowrap; // Prevent text from wrapping to the next line
            overflow: hidden; // Hide any overflow text
            text-overflow: ellipsis; // Add ellipsis (...) for overflow text
          }

          .GameInfo__cdkey--price {
            font-size: 30px;
            font-weight: 700;
            margin-top: 10px;
            white-space: nowrap; // Prevent text from wrapping to the next line
            overflow: hidden; // Hide any overflow text
            text-overflow: ellipsis; // Add ellipsis (...) for overflow text
          }

          .GameInfo__cdkey--buy--container {
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: $colorMainBackgroud;
            border-radius: 8px;
            padding: 10px 20px;
            margin-top: 30px;
            cursor: pointer;

            .GameInfo__cdkey--buy--icon {
              width: 30px;
              height: 20px;
              object-fit: cover;
            }

            .GameInfo__cdkey--buy--button {
              color: #fff;
              text-transform: uppercase;
              margin-left: 10px;
              font-family: Arial;
              font-size: 17px;
              font-weight: 600;
              cursor: pointer;
            }
          }
          .price_block{
            position: relative;
            width: 119px;
            height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            .text_45{
              height: 25px;
              overflow-wrap: break-word;
              color: rgba(205, 204, 208, 1);
              font-size: 24px;
              font-family: ArialMT;
              font-weight: NaN;
              text-align: left;
              white-space: nowrap;
              line-height: 28px;
              margin: 25px 0 0 1px;
              position: relative;
              &:before {
                content: '';
                position: absolute;
                top: 10px;
                transform: rotate(10deg);
                width:79px;
                height: 2px;
                background-color: #322D38;
                transform-origin: 0 100%;
                width: 100%;
              }
            }
            .text-wrapper_18{
              height: 37px;
              background: url('../../images/dialog/discount_tip.png') 0px 0px no-repeat;
              background-size: 38px 38px;
              margin-left: -1px;
              width: 37px;
              display: flex;
              flex-direction: column;
              .text_46{
                width: 32px;
                height: 11px;
                overflow-wrap: break-word;
                color: rgba(239, 57, 55, 1);
                font-size: 12px;
                font-family: ArialMT;
                font-weight: NaN;
                text-align: center;
                white-space: nowrap;
                line-height: 14px;
                margin: 12px 0 0 3px;
              }
            }
            .image_4{
              position: absolute;
              left: 0;
              top: 28px;
              width: 63px;
              height: 18px;
            }
          }
        }
      }
    }

    .Games__primary {
      .Games__primary--wrapper {
        // max-width: 1024px;
        background-color: $colorMainBlack;
        min-height: 386px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        // margin-bottom: 30px;
        padding: 60px 180px;
        // border-bottom: 2px solid $colorMainBackgroud;

        .PrimaryQuestsList {
          opacity: 0;
          visibility: hidden;
          transition: 500ms ease all;
          width: 100%;
          // display: flex;
          // flex-wrap: wrap;
          // flex-direction: row;
          max-height: 0;
          // justify-content: flex-start;
          // align-items: flex-start;
          transition: ease 300ms transform, ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms max-height, ease 300ms padding, ease 300ms margin;
          transform: translateY(50px);
          display: grid;
          grid-template-columns: repeat(4, 1fr); // Create a grid with 4 columns
          gap: 22px 9px; // Add space between grid items

          @media screen and (max-width: 767px) {
            flex-direction: column !important;

            .QuestCard {
              width: 100% !important;
              padding: 0 50px;

            }
          }

          &.active {
            visibility: visible;
            opacity: 1;
            max-height: inherit;
            min-height: 35px;
            transform: translateY(0px);
          }

          .QuestCard {
            text-decoration: none;
            // display: inline-flex;
            position: relative;
            width: 100%;
            border-radius: 8px;
            background-color: $colorMainBackgroud;
            // margin-right: calc(20px + (20px / 2));
            // margin-bottom: 20px;
            cursor: pointer;
            flex-direction: column;

            &:nth-child(3n) {
              margin-right: 0px;
            }

            .QuestCard__wrapper {
              position: relative;
              // border-radius: 8px;

              // overflow: hidden;

              .QuestCard__image {
                display: flex;
                // border-radius: 8px;
                overflow: hidden;
                /* 确保放大的图片不会超出容器 */

                width: 100%;
                height: 212px;
                position: relative;

                &:before {
                  content: '';
                  padding-top: 56.25%;
                  display: block;
                }

                figure {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  border-top-left-radius: 8px;
                  border-top-right-radius: 8px;
                  margin: 0;
                  top: 0;
                  left: 0;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                  /* 现有样式保持不变 */
                  transition: transform 0.3s ease;
                  /* 添加平滑过渡动画，0.3秒完成 */
                }
              }

              /* 当鼠标悬停在 QuestCard__wrapper 上时，放大其中的 figure 元素 */
              &:hover {
                .QuestCard__image figure {
                  transform: scale(1.2);
                  /* 放大到原始大小的 1.2 倍 */
                }
              }

              .QuestCard__details {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                color: #CDCCD0;
                // margin-top: -30px;

                // .QuestCard__background-top {
                //   position: absolute;
                //   width: 100%;
                //   height: 30px;
                //   top: 0;
                //   background-color: #212227;
                // }

                // .QuestCard__background-bottom {
                //   position: absolute;
                //   width: 100%;
                //   height: calc(100% - 30px);
                //   bottom: 0;
                //   background-color: #212227;
                // }

                .QuestCard__details-wrapper {
                  display: flex;
                  flex-direction: column;
                  z-index: 1;

                  .red-line {
                    width: 100%;
                    height: 3px;
                    background-color: $colorMainOrange;
                  }

                  .QuestCard__details_details_price_date {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 20px;
                    height: 60px;

                    .price {
                      font-size: 24px;
                      font-weight: bold;
                      color: #CDCCD0;
                    }

                    .time {
                      font-size: 18px;
                      font-weight: 600;
                      color: #CDCCD0;
                    }
                  }

                  .QuestCard__details--bottom {
                    background-color: $colorMainOrange;
                    width: 100%;
                    height: 100%;
                    color: #322D38;
                    cursor: pointer;
                    text-transform: uppercase;
                    text-align: center;
                    font-weight: normal;
                    font-weight: 600;
                    font-size: 16px;
                    padding: 10px 0;
                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 8px;

                    &.apply {
                      background-color: $colorMainOrange; // Black background
                    }

                    &.joined {
                      background-color: $colorMainOrange; // Black background
                    }

                    &.others {
                      background-color: $colorWhiteFontQuestDetail; // White background
                    }
                  }

                  .QuestCard__details--row {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    margin: 0 30px 20px 30px;

                    &:nth-child(1) {
                      margin-top: 15px;
                    }

                    .QuestCard__details--icon {
                      color: #FFF;
                      margin-right: 10px;
                      font-size: 20px;
                      color: $colorGray;
                    }

                    .QuestCard__details--info {
                      width: 100%;
                      height: 62px;
                      display: flex;
                      align-items: center;

                      .QuestCard__details--name {
                        font-weight: 700;
                        color: #CDCCD0;
                        font-family: "arial";
                        text-transform: capitalize;
                        font-size: 16px;
                        text-align: center;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        /* 限制显示行数 */
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        /* 确保容器高度占满父元素 */
                      }

                      // Second Row
                      .sub-info {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 100%;
                        margin-top: 2px;

                        .QuestCard__details--game-name {
                          font-size: 10px;
                          color: $colorGray;
                          font-family: "Roboto";
                        }

                        .QuestCard__details--date-interval {
                          margin-left: auto;
                          font-size: 12px;
                          color: $colorGray;
                          font-family: "Roboto";
                        }
                      }
                    }

                    .SeeQuest {
                      // background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
                      background-color: $colorMainOrange;
                      width: 100%;
                      height: 100%;
                      color: $colorGrayNew;
                      cursor: pointer;
                      text-transform: uppercase;
                      text-align: center;
                      font-weight: normal;
                      font-weight: 700;
                      font-size: 16px;
                      padding: 20px 0;
                      // margin: 10px 0;

                      &.disabled {
                        cursor: disabled;
                        background-color: $colorMainBackgroud;
                        background-color: $colorMainOrange;
                        width: 100%;
                        height: 100%;
                        color: $colorGrayNew;
                        text-transform: uppercase;
                        text-align: center;
                        font-weight: normal;
                        font-weight: 700;
                        font-size: 16px;
                        padding: 20px 0;
                      }
                    }

                    .QuestCard__details--open-slots {
                      margin-bottom: 15px;
                      width: 100%;
                      color: $colorGray;
                      text-align: center;
                      font-size: 12px;
                    }
                  }
                }
              }

              .QuestCard__level {
                position: absolute;
                top: 15px;
                left: 0;
                // width: 33%;
                // height: 25px;
                padding: 5px 10px;
                text-transform: uppercase;
                background-color: $colorMainOrange;
                color: #fff;
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                font-size: 16px;

                &.type-1 {
                  background-color: $colorMainOrange; // Black background
                  color: #fff; // White text
                }

                &.type-2 {
                  background-color: #fff; // White background
                  color: $colorMainOrange; // Black text
                }
              }

              .QuestCard__info-panel {
                position: absolute;
                bottom: -90%;
                right: -90%;
                height: 90%;
                width: 90%;
                padding: 20px;
                background-color: #18191D;
                transition: 300ms ease all;
                z-index: 2;
                display: flex;
                flex-direction: column;

                .QuestCard__info-panel--title {
                  color: #4BE0CA;
                  font-size: 15px;
                  font-weight: 700;
                  padding-bottom: 10px;
                  margin: 0;
                }

                .QuestCard__info-panel--details {
                  color: #FFF;
                  font-size: 13px;
                  margin: 0;
                }

                .QuestCard__info-panel--link {
                  color: #4BE0CA;
                  font-size: 14px;
                  margin: 0;
                }
              }
            }
          }
        }

        .PrimaryGameMedia {
          opacity: 0;
          visibility: hidden;
          transition: 500ms ease all;
          width: 100%;
          display: flex;
          // flex-wrap: wrap;
          flex-direction: row;
          max-height: 0;
          // justify-content: flex-start;
          // align-items: flex-start;
          transition: ease 300ms transform, ease 300ms opacity, ease 300ms visibility, ease 300ms height, ease 300ms max-height, ease 300ms padding, ease 300ms margin;
          transform: translateY(50px);

          &.active {
            visibility: visible;
            opacity: 1;
            max-height: inherit;
            transform: translateY(0px);
          }

          .NoGallery {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            // color: #fff;
            font-size: 20px;
          }

          .GameGallery {
            width: 100%;

            .GameGallery__wrapper {
              position: relative;
              display: flex;
              flex-direction: column;
              overflow: hidden;
              // background-color: rgba(0, 0, 0, 0.5);

              @media #{$mobileOnly} {
                width: 100%;
                flex-direction: column;
              }

              .GameGallery__Item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .GameGallery__title {
                  text-align: center;
                  text-transform: uppercase;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 18px;
                  font-weight: 500;
                }

                .GameGallery__line {
                  width: 10%;
                  height: 2px;
                  background-color: $colorMainOrange;
                }

                .GameGallery__image__content {
                  width: 100%;
                  display: grid;
                  grid-template-columns: repeat(3, 1fr); // Create a grid with 4 columns
                  gap: 20px; // Add space between grid items
                  padding-top: 20px;
                }

                .GameGallery__image {
                  display: flex;
                  // border-radius: 8px;

                  width: 100%;
                  height: 250px;
                  position: relative;

                  &:before {
                    content: '';
                    padding-top: 56.25%;
                    display: block;
                  }

                  figure {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    margin: 0;
                    top: 0;
                    left: 0;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                  }
                }
              }

              .GameGallery__Item__Bottom {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 40px;

                .GameGallery__title {
                  text-align: center;
                  text-transform: uppercase;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 18px;
                  font-weight: 500;
                }

                .GameGallery__line {
                  width: 10%;
                  height: 2px;
                  background-color: $colorMainOrange;
                }

                .GameGallery__video__content {
                  width: 100%;
                  display: grid;
                  grid-template-columns: repeat(3, 1fr); // Create a grid with 4 columns
                  gap: 20px; // Add space between grid items
                  padding-top: 20px;
                }

                .GameGallery__video {
                  display: flex;
                  // border-radius: 8px;

                  .video-info {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    background-color: rgba(0, 0, 0, 0.7);
                    color: #fff;
                    padding: 5px 10px;
                    border-radius: 4px;
                  }

                  .video-name {
                    font-size: 14px;
                    font-weight: bold;
                  }

                  .video-duration {
                    font-size: 12px;
                  }

                  width: 100%;
                  height: 250px;
                  position: relative;

                  &:before {
                    content: '';
                    padding-top: 56.25%;
                    display: block;
                  }

                  .youtube-video {
                    position: absolute;
                    left: 0;
                    top: 0;
                  }

                }
              }
            }
          }
        }
      }
    }
  }

  .tooltip {
    position: relative;
    z-index: 101;
  }

  .tooltiptext {
    font-size: 10px;
    font-weight: 400;
    padding: 20px 0;
    z-index: 100;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: $colorBackground;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    bottom: 25px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}

.tab-bar-new {
  // background: #28222E;
  background: linear-gradient(to bottom, #211C27, #2D2833);
  padding: 10px 180px;

  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: #CAC9CE;
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  // align-items: center;
  // justify-content: space-between;

  @media screen and (max-width: 768px) {
    padding: 10px 25px;
  }

  .tab-span {
    cursor: pointer;

    padding: 5px 10px;
    border-radius: 10px;

    @include vw-pixels(('margin-right': 30), $vwPixelsScale);

    @media #{$mobileOnly} {
      @include vw-pixels(('margin-right': 30));
    }

    @media #{$macroScreens} {
      margin-right: 30px
    }

    &.active {
      background-color: #3A3440;
      color: $colorMainOrange
    }
  }
}

.tab-line {
  background-color: $colorMainBackgroud;
  height: 2px;
  width: 100%;
}

.cdkey-modal-content {
  background: #1A1B1F;
  width: 100%;


  .cdkey-modal-header {
    position: relative;
    height: 200px;

    .game-pic {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-bottom: 2px solid #EF393F;
    }

    .game-info1 {
      width: 110px;
      height: 110px;
      position: absolute;
      background: #E53935;
      top: -4px;
      right: 27px;
      display: flex;
      flex-direction: column;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -5px;
        width: 0px;
        height: 0px;
        border-right: 6px solid #E53935;
        border-top: 4px solid transparent;
      }


      &:after {
        content: '';
        position: absolute;
        bottom: -19px;
        left: 0;
        width: 0;
        height: 0;
        border-left: 55px solid transparent;
        border-right: 0;
        border-top: 20px solid #E53935;
      }

      .triangle-right {
        position: absolute;
        bottom: -19px;
        right: 1px;
        width: 0;
        height: 0;
        border-left: 0;
        border-right: 55px solid transparent;
        border-top: 20px solid #E53935;
      }

      .price-tag {
        text-align: center;
        color: white;
        border-radius: 4px;
        font-size: 30px;
        font-weight: 900;
        margin: 5px 0;
      }

      .stock-tag {
        //background: #211C27;
        color: #000000;
        //padding: 4px 8px;
        font-size: 11px;
        font-weight: 700;
        text-align: center;
        margin-top:10px;
      }
      .stock-num-tag {
        //background: #211C27;
        color: #000000;
        //padding: 4px 8px;
        font-size: 11px;
        font-weight: 700;
        text-align: center;
        z-index:10;
      }
      .bottom-price{
        display: flex;
        flex-direction: row;
      }
      .section-discount{
        background-color: #3C3940;
        left: 70px;
        top: 11px;
        //width: 134px;
        height: 23px;
        display: flex;
        flex-direction: row;
        margin:0 3px;
        .section-block{
          background-color: rgba(60, 57, 64, 1);
          position: relative;
          //width: 48px;
          height: 23px;
          display: flex;
          flex-direction: row;
          flex:1;
          .text-price{
            width: 100%;
            height: 16px;
            overflow-wrap: break-word;
            color: #7D7A81;
            font-size: 16px;
            font-family: ArialMT;
            font-weight: 700;
            text-align: center;
            white-space: nowrap;
            //line-height: 16px;
            //margin: 4px 0 0 10px;
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              transform: rotate(18deg);
              width:100%;
              height: 2px;
              background-color: #322D38;
              transform-origin: 0 100%;
            }
          }
          img{
            position: absolute;
            left: 8px;
            top: 7px;
            width: 30px;
            height: 10px;
          }
        }
        .text-sale{
          width: 100%;
          height: 23px;
          overflow-wrap: break-word;
          background-color: #211C27;
          color: #EF3937;
          font-size: 16px;
          font-family: ArialMT;
          font-weight: 700;
          text-align: center;
          white-space: nowrap;
          //line-height: 16px;
          //margin: 5px 5px 0 4px;
          flex:1;
        }
      }
    }
  }

  .form-section {
    background-color: #211C27;

    .form-group {
      padding: 12px 20px;
      border-bottom: 1px solid #595261;

      label {
        display: block;
        color: #fff;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 19px;
        color: #CDCCD0;
        line-height: 19px;
      }
    }

    .platform-options,
    .purchase-method-options,
    .payment-method-options {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      button {
        padding: 8px 0;
        border: 2px solid #595261;
        border-radius: 8px;
        background: #2E2933;
        font-weight: 400;
        font-size: 13px;
        color: #7E7B82;
        line-height: 14px;
        cursor: pointer;
        width: 130px;

        &.active {
          border-color: #E53935;
          color: #E53935;
        }
      }
    }

    .email-note {
      margin-top: 10px;
      color: #666;
      font-size: 13px;

      .email-link {
        font-size: 18px;
        color: #25E2C5;
        text-decoration: none;
        margin: 1px;
      }

      .email-input {
        width: 100%;
        padding: 8px 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-top: 8px;
        font-size: 14px;

        &:focus {
          outline: none;
          border-color: #007bff;
        }
      }
    }

    .quantity-controls {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .quantity-group {
    background-color: #322D38;
    padding: 10px 25px 0;
  }

  .quantity-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      display: block;
      color: #fff;
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 19px;
      color: #CDCCD0;
      line-height: 19px;
    }
  }

  .pay-button {
    width: 100%;
    padding: 15px;
    background: #977b7a;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    &:hover {
      //background: darken(#E53935, 5%);
    }

    &.active {
      //transform: translateY(1px);
      background: darken(#E53935, 5%);
      cursor: pointer;
    }
  }
}