.static {
	color: #fff;
	padding-top: 150px;
	padding: 10px;
	margin: 0 auto;
	max-width: 800px;
	letter-spacing: 1.5px;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	
	h1 {
		text-align: center;
	}

	h2 {
		margin-top: 40px;
		font-size: 20px;
	}

	a {
		color: #5abfd8;
	}

	b {
		text-decoration: underline;
	}

	ol {
		ol {
			list-style-type: lower-alpha;
		}
	}
}

.TermsOfUse,
.PrivacyPolicy,
.QuestParticipationAgreement {
	padding-top: 150px;
	padding-bottom: 50px;
}

.QuestParticipationAgreement {
	ol {
		li {
			margin: 14px 0;
		}
	}
}