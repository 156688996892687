@import "../../utils/_variables";

.video-widget-container {

  //   width: 100%;
  // width: calc((982 / 1920) * 100vw);
  // height: calc((588 / 1080) * 100vh);
  position: relative;
  width: 100%;
  height: 100%;

  // @media #{$tabletOnly} {
  //   @include vw-pixels(("height": 588,
  //       // "min-width": 1426,
  //     ));
  // }


  // @media #{$tabletAndLarger} {
  //   @include vw-pixels(("height": 588,
  //       // "max-width": 1100,
  //     ));
  // }

  // @media (min-width: 1550px) {
  //   @include vw-pixels(("height": 588,
  //       // "max-width": 1100,
  //     ));
  // }

  // background: linear-gradient(
  //   to bottom,
  //   rgba(25, 116, 250, 0) 50%,
  //   rgba(25, 116, 250, 0.2)
  // );
  background-image: url('../../images/eclipse.gif');
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: 15%;
  background-position: center;
}

.video-widget-image {
  width: 100%;

  // @media #{$tabletAndLarger} {
  //   @include vw-pixels(("height": 588,
  //       // "max-width": 1100,
  //     ));
  // }
}

.preview-image {
  overflow: hidden !important;
  display: flex;
  width: 100%;
  height: 588px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.66);

  // @include vw-pixels(("height": 588,
  //   ));

  .img-man {
    // object-fit: cover !important;
    height: 100%;
  }
}

.video-widget-player {
  margin-top: 0% !important;
  width: 100%;
  height: 588px;
}

.video-widget-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  border: 0;
}

.after-video-image {
  // pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  // width: 0%;
  // opacity: 0.6;
  transform: translateX(-50%) translateY(-50%);

  img {
    @include vw-pixels(("height": 100,
      ));
  }
}